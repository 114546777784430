<template>
  <div class="container mt-50 mb-20 pb-20">
    <div>
      <router-link to="/">
        <img src="../../assets/images/logo.png" width="200px"/>
      </router-link>
    </div>
    <el-card class="mt-20" shadow="hover" :body-style="{ padding: '20px' }">
      <div
        class="d-flex justify-content-between align-items-center"
        slot="header"
      >
        <h5>{{ $route.meta.title }}</h5>
        <div class="d-flex justify-content-end align-items-center">
          <el-input placeholder="关键词搜索" v-model="params.keyword">
          </el-input>
        </div>
      </div>
      <div class="tagbar mb-20">
        <ul class="tag-list">
          <li
            @click="changeCategory()"
            :class="{ active: !params.category_id }"
          >
            全部
          </li>
          <li
            :class="{ active: item.id == params.category_id }"
            v-for="item in category"
            :key="item.id"
            @click="changeCategory(item)"
          >
            {{ item.name }}
          </li>
        </ul>
      </div>

      <router-link
        v-for="item in lists"
        :key="'item' + item.id"
        :to="{ path: '/help/detail/' + item.id }"
        class="block flex-fill"
        target="_blank"
      >
        <img
          v-if="item.thumb"
          :src="viewThumb(item.thumbData.thumb, 'medium')"
          class="thumb"
        />

        <div class="block-body">
          <div class="block-body-title">
            {{ item.title }}
          </div>
          <div class="block-body-desc">
            {{ item.description }}
          </div>
          <div class="block-body-desc">
            <span> {{ item.createDate }} </span>
            <span> {{ $utils.contrastTime(item.create_time) }}</span>
          </div>
        </div>
      </router-link>

      <div class="text-center mt-20" v-if="params.pages > 1">
        <el-pagination
          @size-change="pageSizeChange"
          @current-change="pageChange"
          :current-page="params.page"
          :page-size="params.pageSize"
          layout="total, prev, pager, next, jumper"
          :total="params.total"
        ></el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "List",
  data() {
    return {
      metaTitle: "",
      loading: false,
      category: [],
      lists: [],
      searchTimer: null, //搜索计时器
      params: {
        category_id: "",
        total: 0,
        page: 1,
        pageSize: this.$config.pageSize,
        keyword: "",
        pages: 1,
      },
    };
  },
  watch: {
    "params.keyword": {
      handler: function () {
        if (this.searchTimer) {
          clearTimeout(this.searchTimer);
        }
        this.searchTimer = setTimeout(() => {
          this.getList();
        }, 1000);
      },
    },
  },
  metaInfo() {
    return {
      title: this.metaTitle + "帮助-" + this.Settings.base.name,
    };
  },
  created() {
    //频道
    this.$api.help.Category.lists().then((res) => {
      this.category = res.lists;
    });
    this.getList();
  },
  mounted() {},
  methods: {
    getList(page) {
      this.params.page = this.$utils.toInt(page) || 1;
      this.loading = true;
      this.$api.help.Help.lists(this.params)
        .then((res) => {
          this.loading = false;
          this.lists = res.data.lists;
          this.params.total = res.data.total;
          this.params.pages = res.data.pages;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    // 分页点击
    pageChange(page) {
      this.getList(page);
    },
    // 点击分页大小
    pageSizeChange(pageSize) {
      this.params.pageSize = pageSize;
      this.getList(this.page);
    },
    changeCategory(item) {
      if (!item) {
        this.metaTitle = "";
        this.params.category_id = 0;
      } else {
        this.metaTitle = item.name + "-";
        this.params.category_id = item.id;
      }
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>